<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>HQ</template>
            <template v-slot="slotProps">
            <fetch-siren-root ref="sirenRoot" :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <hq-navigation :links="slotProps.links"></hq-navigation>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': (details || loadingDetails) && !hideTable}">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <div class="p-2 overflow-y-auto h-full" :class="{'hidden': hideTable}">
                                            <clients-pagination ref="clientList" :response="response" :entities="getClients(response)" @setDetails="showDetails" @setLoading="setLoading" @altViewClicked="altViewClicked" >
                                                <template v-slot:additional-buttons>
                                                    <template v-for="(action, index) in response.body.actions.filter(action => action.name === 'create-new-client')">
                                                        <form-action :response="details" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" @success="invoiceCreated"></form-action>

                                                    </template>
                                                </template>
                                            </clients-pagination>
                                        </div>
                                        <SingleClientDetails ref="clientDetails" :details="details" :loadingDetails="loadingDetails" :hideTable="hideTable" @setLoading="setLoading" @getDetails="getDetails" @hideTable="toggleHideTable" @saveClicked="saveClicked" app="hq" activeClass="bg-v3-gray-50 bg-opacity-20 dark:bg-v3-gray-50 dark:bg-opacity-20"/>
                                    </template>
                                </div>
                            </div>
                        </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import HqNavigation from "@/v3/components/navigation/HqNavigation.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import ClientsPagination from "@/v3/components/pagination/ClientsPagination.vue";
import SingleClientDetails from "@/v3/pages/SingleClientDetails.vue";
import fileDownload from "js-file-download";

export default {
    inject: ["$validator"],
    components: {
        SingleClientDetails,
        ClientsPagination,
        FormAction,
        HqNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,
    },
    data() {
        return {
            slotProps: null,
            users: null,
            details: null,
            detailsOpen: false,
            loadingDetails: false,
            entities: null,
            hideTable: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        }

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getClients(response) {
            if (!response) {
                return null;
            }
            return response.body.entities.filter(entity => entity.rels.contains('client'));
        },
        showDetails(details){
            this.$setLaravelValidationErrorsFromResponse('');
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.$refs.clientDetails.editing = false;
            this.$refs.clientDetails.tabOpen = 'basics';
            this.details = null;
            this.loadingDetails = true;
        },
        getDetails(entity){
            this.$refs.clientList.getDetails(entity);
        },
        toggleHideTable(){
            this.hideTable = !this.hideTable;
        },
        altViewClicked(){
            this.details = null;
        },
        invoiceCreated(res){
            this.$refs.sirenRoot.load();
            this.$refs.clientList.setDetailsOpen(res.body);
            this.showDetails(res.body);
        },
        saveClicked() {
            if(!this.submitting) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submitting = true;
                this.$refs.clientDetails.updateAction.perform(this.$refs.clientDetails.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submitting = false;
                        return;
                    }
                    this.$refs.clientDetails.editing = false;
                    this.submitting = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.details = res.body;
                    this.$emit('success', res);
                }).catch(error => {
                    this.submitting = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_light"
        }

    }
}
</script>

